import { Link } from "gatsby"
import * as React from "react"
import coffeeHome from "../../assets/images/coffee-home.webp"
import key4 from "../../assets/images/consumer-new.webp"
import consumer from "../../assets/images/consumer.svg"
import endToEnd from "../../assets/images/end-to-end.svg"
import key1 from "../../assets/images/Estate-new.webp"
import facilatorWeb from "../../assets/images/web-image.webp"
import farmer from "../../assets/images/farmer.svg"
import future from "../../assets/images/future.svg"
import key3 from "../../assets/images/Hospitality_restaurant.png"
import arrow from "../../assets/images/icons/arrow.svg"
import facilatorMobile from "../../assets/images/mobile.webp"
import key2 from "../../assets/images/Manufacturer_logs_on_road.png"
import facilatorTablet from "../../assets/images/tablet.webp"
import Whitepaper from "../models/whitepaper"

const Hero = ({ pageContext: { translate } }) => {
  return (
    <>
      {
        <div className="hero">
          <div className="top-hero">
            <div className="content">
              <div className="row mx-0">
                <div className="col-12 col-md-6 align-items-center pt-3 pt-md-0 d-md-flex">
                  <div className="title pr-lg-4">
                    <h1>{`${translate.t?.we_create_impact_coffee}`}</h1>
                    <h3 className="pr-lg-5">{`${translate.t?.join_our_impact_journey}`}</h3>
                    <div className="row mx-0 mt-md-5">
                      <div className="mr-md-3">
                        <Whitepaper
                        pageContext={{ translate }}
                        btnText={translate.t?.get_started}
                        styler="whitebtn"
                        title={translate.t?.contact_form}
                        description={translate.t?.get_in_contact}
                        type="contact"
                        submitBtnText={translate.t?.submit}
                      />
                      </div>
  <Link
                          to={`${
                            translate.currentLang
                              ? "/" + translate.currentLang + "/"
                              : "/"
                          }solutions/`}
                          className="btndefault greenbtn  mx-2 mt-md-0"
                        >
                          {translate.t?.learn_more}
                        </Link>
                      

                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 coffee-image">
                  <img
                    src={coffeeHome}
                    alt="home-image"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-hero">
            <div className="content">
              <h1 className="fnt-22 fnt-md-28 fnt-lg-34 fw-bold">
                {translate.t?.find_your_role}
              </h1>
              <p className="fnt-14 fnt-md-16 fnt-lg-20">
                {translate.t?.home_description}
              </p>
              <div className="keys">
                <Link
                  to={`${
                    translate.currentLang
                      ? "/" + translate.currentLang + "/"
                      : "/"
                  }estates/`}
                  className="box"
                >
                  <img src={key1} className="desktop" alt="Estate" />
                  <img src={key1} className="tablet" alt="Estate" />
                  <div className="show-hover">
                    <h2>{translate.t?.estates}</h2>
                    <div className="stater">
                      <p>{translate.t?.from_smallholder_farms}</p>
                      <img src={arrow} alt="Arrow" />
                    </div>
                  </div>
                </Link>
                <Link
                  to={`${
                    translate.currentLang
                      ? "/" + translate.currentLang + "/"
                      : "/"
                  }coffee-roaster/`}
                  className="box"
                >
                  <img src={key2} className="desktop" alt="Roaster" />
                  <img src={key2} className="tablet" alt="Roaster" />
                  <div className="show-hover">
                    <h2>{translate.t?.roasters}</h2>
                    <div className="stater">
                      <p>{translate.t?.differentiate_products}</p>
                      <img src={arrow} alt="Arrow" />
                    </div>
                  </div>
                </Link>
                <Link
                  to={`${
                    translate.currentLang
                      ? "/" + translate.currentLang + "/"
                      : "/"
                  }hospitality/`}
                  className="box"
                >
                  <img src={key3} className="desktop" alt="Hospitality" />
                  <img src={key3} className="tablet" alt="Hospitality" />
                  <div className="show-hover">
                    <h2>{translate.t?.hospitality}</h2>
                    <div className="stater">
                      <p>{translate.t?.craft_unique_coffee}</p>
                      <img src={arrow} alt="Arrow" />
                    </div>
                  </div>
                </Link>
                <Link
                  to={`${
                    translate.currentLang
                      ? "/" + translate.currentLang + "/"
                      : "/"
                  }consumer/`}
                  className="box"
                >
                  <img src={key4} className="desktop" alt="Consumer" />
                  <img src={key4} className="tablet" alt="Consumer" />
                  <div className="show-hover">
                    <h2>{translate.t?.consumer}</h2>
                    <div className="stater">
                      <p>{translate.t?.better_coffee_impacting_people}</p>
                      <img src={arrow} alt="Arrow" />
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="collaborate py-5">
            <div className="collaborate-faster row mx-0 mx-md-auto justify-content-center">
              <div className="text-center collaborate-title px-3 px-lg-0">
                <h1 className="fnt-22 fnt-md-28 fnt-lg-34 fw-bold mt-lg-5">
                {translate.t?.collaborate}
                {/* {" "} */}
                </h1>
                <div className="figure py-lg-3 mt-25 mb-3">
                  {/* <Link
                    to={`${process.env.GATSBY_SSO_WEB}/sign-up`}
                    className="btndefault greenbtn mx-0"
                  >
                    Request a demo
                  </Link> */}
                  <Whitepaper
                    pageContext={{ translate }}
                    btnText={translate.t?.request_a_demo}
                    styler="greenbtn"
                    title={translate.t?.contact_form}
                    description={translate.t?.contact_form_description}
                    type="contact"
                    submitBtnText={translate.t?.submit}
                  />
                </div>
              </div>
              {/* <div className="row mx-0 mx-md-auto my-lg-4">
                <div className="col-12 col-md-6 col-lg-3 my-2">
                  <div className="collaborate-box h-100 p-3">
                    <div className="py-3 text-center text-md-left">
                      <img src={farmer} alt="farmer" />
                    </div>
                    <h3 className="fnt-14 fnt-md-16 fnt-lg-18 fw-bold py-2">
                    {translate.t?.empower_farmers}{" "}
                    </h3>
                    <p>
                    {translate.t?.estate_tool}{" "}
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3 my-2">
                  <div className="collaborate-box h-100 p-3">
                    <div className="py-3 text-center text-md-left">
                      <img src={future} alt="future" />
                    </div>
                    <h3 className="fnt-14 fnt-md-16 fnt-lg-18 fw-bold py-2">
                    {translate.t?.future_proof}{" "}
                    </h3>
                    <p>
                    {translate.t?.designed_for_future}
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3 my-2">
                  <div className="collaborate-box h-100 p-3">
                    <div className="py-3 text-center text-md-left">
                      <img src={consumer} alt="consumer" />
                    </div>
                    <h3 className="fnt-14 fnt-md-16 fnt-lg-18 fw-bold py-2">
                    {translate.t?.consumer_experiences}{" "}
                    </h3>
                    <p>
                    {translate.t?.every_bean_sold}
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3 my-2">
                  <div className="collaborate-box h-100 p-3">
                    <div className="py-3 text-center text-md-left">
                      <img src={endToEnd} alt="End-to-end" />
                    </div>
                    <h3 className="fnt-14 fnt-md-16 fnt-lg-18 fw-bold py-2">
                    {translate.t?.end_to_end_management}{" "}
                    </h3>
                    <p>
                    {translate.t?.access_erp_system}
                    </p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          {/* <div className="smarter py-5">
            <div className="smarter-way row justify-content-center pt-lg-4">
              <div className="text-md-center smarter-title px-3 px-md-0 mb-5">
                <h1 className="mb-3 fnt-22 fnt-md-28 fnt-lg-34 fw-bold txt-clr223">
                 {translate.t?.smarter_way}
                </h1>
                <p className="fnt-16 fnt-md-18 fnt-lg-20 fw-m txt-clr223 px-lg-5">
                 {translate.t?.eow_provide_platform}{" "}
                </p>
              </div> */}
              {/* <div className="row w-100 mx-0 smart-btns px-3 mx-lg-auto mt-5">
                <div className="col-3 col-md fw-bold smarter-box text-center py-2 mx-md-1">
                  <Link className="mx-1 mb-0">Farming</Link>
                </div>
                <div className="col-3 col-md fw-bold smarter-box text-center py-2 mx-md-1">
                  <Link className="b-0">Wet Mill</Link>
                </div>
                <div className="col-3 col-md fw-bold smarter-box text-center py-2 mx-md-1">
                  <Link className="b-0">Dry Mill</Link>
                </div>
                <div className="col-3 col-md fw-bold smarter-box text-center py-2 mx-md-1">
                  <Link className="b-0">Exporter</Link>
                </div>
                <div className="col-3 col-md fw-bold smarter-box text-center py-2 mx-md-1">
                  <Link className="b-0">Importer</Link>
                </div>
                <div className="col-3 col-md fw-bold smarter-box text-center py-2 mx-md-1">
                  <Link className="mx-1 mb-0">Roaster</Link>
                </div>
                <div className="col-3 col-md fw-bold smarter-box text-center py-2 mx-md-1">
                  <Link className="b-0">Consumer</Link>
                </div>
              </div> */}
              {/* <div className="mt-3 d-block d-md-none">
                <img
                  src={facilatorMobile}
                  alt="facilator"
                  className="img-fluid"
                />
              </div>
              <div className="mt-4 d-none d-md-block d-lg-none">
                <img
                  src={facilatorTablet}
                  alt="facilator"
                  className="img-fluid"
                />
              </div>
              <div className="mt-5 d-none d-lg-block">
                <img src={facilatorWeb} alt="facilator" className="img-fluid" />
              </div>
            </div>
          </div> */}
        </div>
      }
    </>
  )
}

export default Hero
